import type {ApolloError} from '@apollo/client';
import {NotificationsOptions, notify as _notify} from '@kyvg/vue3-notification';

export function useNotifications() {
  const {t} = useI18n();

  function error(text = 'notifications.error') {
    return notify({
      text,
      type: 'error,',
    });
  }

  function notify(args: (NotificationsOptions & {
    translationParams?: Record<string, unknown>
  }) | string) {
    if (typeof args === 'string') {
      args = t(args);
    } else {
      if (typeof args.text === 'string') {
        args.text = t(args.text, args.translationParams ?? {});
      }

      if (typeof args.title === 'string') {
        args.title = t(args.title, args.translationParams ?? {});
      }
    }

    return _notify(args);
  }

  function notifyGraphQLError(apolloError: ApolloError, options: {
    message?: string
  } = {}) {
    let text = options.message;

    if (apolloError.message === 'validation') {
      // Optimistic logging of first available validation message.
      text = Object.entries(apolloError.graphQLErrors[0].extensions.validation)[0][1][0];
    }

    error(text);
  }

  return {
    error,
    notify,
    notifyGraphQLError,
  };
}

